import React from "react";
import Popup from "reactjs-popup";
import { Grid, Card, Table, Page, colors, Form } from "tabler-react";

class InfoPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.openInfoPopup,
    };
    //this.openModal = this.props.openPopup;
    this.closeInfoModal = this.closeInfoModal.bind(this);
    console.log(this.props.openInfoPopup, "DARA");
  }

  closeInfoModal() {
    this.props.onChange(false);
  }

  submitReply(e) {
    this.props.onChange(false);
  }

  render() {
    // console.log('admin-engage-score',this.props.openInfoPopup.data)
    const openInfoPopup = this.props.openInfoPopup.open;
    const replyOption = this.props.openInfoPopup.reply || this.state.reply;
    const message = this.props.openInfoPopup.data;
    // const fallback_1=this.props.openInfoPopup.data.customerDetail[0].
    console.log(this.props.openInfoPopup.data, "DATA");
    // console.log('admin-engage-score-a',message)
    return (
      <div>
        <Popup
          open={openInfoPopup}
          onClose={this.closeInfoModal}
          closeOnDocumentClick
        >
          <div className="card">
            <div className="card-header">
              <div className="card-options">
                <a
                  className="card-options-remove cursorPointer"
                  data-toggle="card-remove"
                  onClick={() => {
                    this.closeInfoModal();
                  }}
                >
                  <i className="fe fe-x"></i>
                </a>
              </div>
            </div>
            <div className="card-body aligncenter">
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter "
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Sl No.</Table.ColHeader>
                    <Table.ColHeader>Entity </Table.ColHeader>
                    <Table.ColHeader>Value</Table.ColHeader>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {message &&
                    Array.isArray(message) &&
                    message.length > 0 &&
                    message.map((value, index) => (
                      <Table.Row key={index}>
                        <Table.Col>{index + 1}</Table.Col>
                        <Table.Col>{value.entity}</Table.Col>
                        {value.entity == "prescription" ? (
                          <Table.Col className="text-nowrap">
                            <a
                              href={value.value}
                              target="_blank"
                              referrerPolicy="no-referrer"
                            >
                              {value.value}
                            </a>
                          </Table.Col>
                        ) : (
                          <Table.Col className="text-nowrap">
                            {value.value}
                          </Table.Col>
                        )}
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default InfoPopup;
