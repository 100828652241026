// @flow

import React from "react";
import {
    Icon,
    Grid,
    Card,
    Table,
    Form,
    Button,
    Page
} from "tabler-react";
import { Link } from 'react-router-dom'
// import SiteWrapperSuperAdmin from "../../SiteWrapper.superadmin.react";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authActions from "./../redux/auth/action";
import { changeLanguage } from './../language/index';

import socketIOClient from 'socket.io-client';
// import { deleteSpec, getSpec } from "./../services/doctor";
// import { createFnb, createMainMenu, deleteFnb, deleteMainMenu, getFnb, getMainMenu } from "../services/hospitalservices";
import SiteWrapperSuperadminReact from "./SiteWrapper.superadmin.react";
import { createMainMenu, deleteMainMenu, getMainMenu } from "../services/user";
var { jwtToken } = localStorage;
// var ENDPOINT = "https://waagentportal.rpsapi.in";
var ENDPOINT = process.env.REACT_APP_baseUrl;
var socket = socketIOClient(ENDPOINT, { query: `authorization=${jwtToken}` });
// import Loader from '../../common/Loader/loader'

const paginationLimit = 10

class MainMenuImages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: this.props.match.params.page,
            mainMenuArr: [],
            totalRecords: 0,
            nextPage: false,
            prevoiusPage: false,
            search: '',
            isLoading: true,
            fileObj: null,
            // openDownloadPopup: false,
        }
        this.search = this.addMainMenuForm.bind(this);
    }


    componentDidMount() {
        if (!this.props.isLoggedIn) {
            this.props.history.push('/login');
        }
        document.title = 'Voiceoc | Main Menu Image List';
        this.callService(this.state.currentPage);
    }

    //handle change
    handleChange = (e, field) => {
        this.setState({ search: e.target.value });
    }

    setLoadingToggle(value) {
        this.setState({ isLoading: value })
    }

    removeMainMenu = (event, id) => {
        if (window.confirm('Are you sure to delete this Image?')) {

            event.preventDefault();
            const { currentPage } = this.state;
            const { search } = this.state

            this.setLoadingToggle(true);

            deleteMainMenu(id)
                .then(response => {
                    toast.success("Image Deleted Successfully.")
                    this.callService(currentPage, search);
                })
                .catch(error => {
                    if (error.response && error.response.data.message)
                        toast.error(error.response.data.message)
                    else if (error.message)
                        toast.error(error.message)
                    else
                        toast.error(error)
                    this.setLoadingToggle(false);
                })
        }
    }


    callService(page, search = false) {
        getMainMenu(page, search)
            .then(res => {
                console.info("res", res)
                this.setState(() => ({
                    mainMenuArr: res.data.result,
                    nextPage: isNaN(res.data.next) ? 0 : res.data.next,
                    totalRecords: isNaN(res.data.total) ? 0 : res.data.total,
                    prevoiusPage: (isNaN(this.state.currentPage) ? 0 : this.state.currentPage) > 1 ? true : false,
                }));
            }).catch(error => {

                if (error.response && error.response.data.message)
                    toast.error(error.response.data.message)
                else if (error.message)
                    toast.error(error.message)
                else
                    toast.error(error)
            })
    }

    pagination(e, page) {
        this.callService(page);
        this.props.history.push("/admin/mainmenu/" + page);
    }

    onChangeFile = (event) => {
        if (event.target.files && event.target.files[0])
            this.setState({ fileObj: event.target.files[0] });
    }

    async addMainMenuForm() {
        try {
            if (this.state.fileObj) {
                let formData = new FormData()
                formData.append("media", this.state.fileObj)
                await createMainMenu(formData)
                toast.success("Main Menu Image Uploaded successfully.")
                this.callService(1);

            }

        } catch (error) {
            if (error.response && error.response.data.message)
                toast.error(error.response.data.message)
            else if (error.message)
                toast.error(error.message)
            else
                toast.error(error)
        }

    }

    handleViewClient(path, client) {
        this.props.adminLoginAsClient(client);
    }

    getPagination() {
        let startRecord = this.props.match.params.page == 1 ? this.props.match.params.page : ((paginationLimit * (this.props.match.params.page - 1)) + 1)
        let endRecord = (parseInt(startRecord) + parseInt(this.state.mainMenuArr.length) - 1);
        if (this.state.totalRecords)
            return startRecord + '-' + endRecord + ' of ' + this.state.totalRecords;
        else
            return startRecord + '-' + endRecord;
    }

    render() {
        const pageNumber = this.props.match.params.page;
        const finalNumeber = (pageNumber - 1) * paginationLimit;
        // return(<h1>WAIT</h1>)
        return (

            <SiteWrapperSuperadminReact>
                <Page.Content>

                    <Grid.Row>
                        <Grid.Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Main Menu Images</Card.Title>
                                    <Form.Group label="" className="ml-auto">
                                        {/* <input id="csv" name="example-text-input" style={{ cursor: 'pointer', border: '1px solid lightgrey' }} type="file" onChange={(e) => this.onChangeFile(e)} required />
                                        <Button color="primary" className="ml-auto1 btn1a"
                                            onClick={(e) => this.addMainMenuForm()}>
                                            Add Main Menu Image
                                        </Button> */}

                                        <Form.InputGroup>
                                            <Form.Input id="csv" name="example-text-input" style={{ cursor: 'pointer', border: '1px solid lightgrey' }} type="file" onChange={(e) => this.onChangeFile(e)} required />
                                            <Form.InputGroupAppend>
                                                <Button color="primary"
                                            onClick={(e) => this.addMainMenuForm()}>
                                            Add Main Menu Image
                                                </Button>
                                            </Form.InputGroupAppend>
                                        </Form.InputGroup>
                                        <Form.InputGroup className="ml-auto1">

                                            <Form.InputGroupAppend>
                                            </Form.InputGroupAppend>
                                        </Form.InputGroup>
                                    </Form.Group>
                                </Card.Header>
                                <Table
                                    cards={true}
                                    striped={true}
                                    responsive={true}
                                    className="table-vcenter table-hover"
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.ColHeader>  {changeLanguage(this.props.siteLanguage, 'sl_no')}</Table.ColHeader>
                                            <Table.ColHeader >menu</Table.ColHeader>
                                            <Table.ColHeader className="center">{changeLanguage(this.props.siteLanguage, 'action')}</Table.ColHeader>

                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.state.mainMenuArr && this.state.mainMenuArr.length ?
                                            this.state.mainMenuArr.map((category, index) => (
                                                <Table.Row key={index}>
                                                    <Table.Col>{index + 1 + finalNumeber}</Table.Col>
                                                    <Table.Col>
                                                        <a href={category.image_data} target="_blank" rel="noopener noreferrer">
                                                            {category.image_data}
                                                        </a>
                                                    </Table.Col>
                                                    <Table.Col className="text-nowrap actions chat-w1">
                                                        <Link
                                                            to="#"
                                                            title={changeLanguage(this.props.siteLanguage, 'click_to_delete')}
                                                            onClick={(e) => this.removeMainMenu(e, category._id)}>
                                                            <Icon prefix="fa" name="trash"></Icon>
                                                        </Link>
                                                    </Table.Col>
                                                </Table.Row>
                                            ))
                                            :
                                            <Table.Row>
                                                <Table.Col colMainMenun={6} >Sorry! Images not found.</Table.Col>
                                            </Table.Row>
                                        }

                                    </Table.Body>
                                </Table>
                                {/* pagination */}
                                <div className="form-group" style={{ float: 'right' }}>
                                    <label className="form-label">

                                    </label>
                                    <div className="selectgroup align-to-right paginations">
                                        <mainmenun>
                                            {this.getPagination()}
                                        </mainmenun>

                                        <i
                                            className={
                                                "fe " +
                                                (this.props.match.params.page <= 1 ? "not-active " : "cursorPointer ") +
                                                " fe-chevron-left"
                                            }
                                            onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) - 1))}
                                            disabled={this.props.match.params.page <= 1}>
                                        </i>

                                        <i
                                            className={
                                                "fe " +
                                                (!this.state.nextPage ? "not-active " : "cursorPointer ") +
                                                " fe-chevron-right"
                                            }
                                            onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) + 1))}
                                            disabled={!this.state.nextPage}></i>
                                    </div>
                                </div>
                            </Card>
                        </Grid.Col>
                    </Grid.Row>
                </Page.Content>
                <ToastContainer autoClose={5000} position={'bottom-right'} />
            </SiteWrapperSuperadminReact>
        )
    }

}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.auth.isAuthenticated,
        toast: state.auth.toast,
        siteLanguage: state.auth.siteLanguage
    }
}

function mapDimainmenutchToProps(dimainmenutch) {
    return {
        adminLoginAsClient: (payload) => {
            dimainmenutch(authActions.adminLoginAsClient(payload))
        },
        setActiveBot: (payload) => {
            dimainmenutch(authActions.setActiveBot(payload))
        }
    }

}

export default withRouter(connect(mapStateToProps, mapDimainmenutchToProps)(MainMenuImages));
// export default Clients;
