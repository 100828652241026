// @flow

import React from "react";
import { Grid, Card, Table, Page, colors, Form, Button } from "tabler-react";
import Select from "react-select";
import Moment from "moment";

import "tabler-react/dist/Tabler.css";
import C3Chart from "react-c3js";
import { Link, withRouter, browserHistory } from "react-router-dom";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import UploadImage from "./UploadImage";
import SiteWrapperAdmin from "../SiteWrapper.admin.react";
import authActions from "../../redux/auth/action";
import clientService from "../../services/client";
import ReadmorePopup from "../Readmore";
import socketIOClient from "socket.io-client";
import { changeLanguage } from "../../language/index";
import $ from "jquery";
import "../../c3jscustom.css";
import "c3/c3.css";
import { deleteDoctorImage, getDoctors } from "../../services/user";
const moment = require("moment");
const _ = require("lodash");
var { jwtToken } = localStorage;
// var ENDPOINT = "https://waagentportal.rpsapi.in";
// var ENDPOINT = 'http://13.235.244.230:5781';

class Chat extends React.Component {
  constructor(props) {
    let dataSingleChart = {
      bindto: "#graph3",
      x: "x",
      columns: [
        ["x", null],
        ["Total Users", null],
      ],
      colors: {
        "Total Users": "#0A49B6",
      },
    };
    super(props);
    this.chatDiv = React.createRef();
    this.state = {
      messageText: "",
      currentPage: this.props.match.params.page,
      nextPage: false,
      totalRecords: 0,
      chatUsers: [],
      messages: [],
      selectChatUser: null,
      selectedFile: "",
      formDisabled: false,
      files: [],
      openPopup: false,
      openImageModal: false,
      page: 1,
      search: "",
      allContextData: [],
      startDate: "",
      endDate: "",
      filterStartDate: "",
      filterEndDate: "",
      doctorData: [],
      showSearch: false,
      mobileNumber: "",

      startDateSingle: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
      endDateSingle: new Date(),
      filterStartDateSingle: new Date(
        Date.now() - 7 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-US"),
      filterEndDateSingle: new Date().toLocaleDateString("en-US"),
      optinGraph: [],
      xAxisSingleData: [],
      countDataSingleUnique: [],
      chartDataAvgReadValue: dataSingleChart,
      searchArry: [],
    };
    this.handleChange = this.handleChange.bind(this);
    // this.onChangeFile = this.onChangeFile.bind(this);
    this.closeImageModal = this.closeImageModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleChange = (type, date) => {
    let changeDate = new Date(date).toLocaleDateString("en-US");
    this.setState({
      [type]: date,
    });
    if (type === "startDate") {
      this.setState({ filterStartDate: changeDate });
    } else {
      this.setState({ filterEndDate: changeDate });
    }
  };

  closeImageModal(data, reload = false) {
    this.setState({
      openImageModal: {
        open: false,
        data: false,
      },
    });
    if (reload) window.location.reload();
  }

  openImageModal(id) {
    this.setState({
      openImageModal: {
        open: true,
        data: id,
      },
    });
  }
  componentWillMount() {}

  async componentDidMount() {
    var hrefSplit = window.location.href.split("?");
    console.log(hrefSplit);
    if (hrefSplit.length > 1) {
      window.location.href = hrefSplit[0];
    }
    document.title = "Voiceoc | Chat";
    // this.callServiceOptin(this.state.filterStartDateSingle,this.state.filterEndDateSingle)

    this.getDoctor(this.props.match.params.page, this.state.search, 100);
  }

  getDoctor(page, search, limit = 100, fromDate, toDate) {
    getDoctors(page, search, limit)
      .then((res) => {
        console.log("resresres", res.data);
        if (res.data.result) {
          this.setState(() => ({
            doctorData: res.data.result,
            nextPage: res.data.next,
            totalRecords: res.data.total,
            prevoiusPage: this.state.currentPage > 1 ? true : false,
          }));
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 201
        ) {
          this.props.logOut();
          this.props.history.push("/login");
        }
        console.log(error);
      });
  }

  getPagination() {
    var page = this.props.match.params.page;
    var startRecord =
      this.props.match.params.page == 1
        ? this.props.match.params.page
        : 100 * (this.props.match.params.page - 1) + 1;
    var endRecord =
      parseInt(startRecord) + parseInt(this.state.doctorData.length) - 1;
    return startRecord + "-" + endRecord + " of " + this.state.totalRecords;
  }

  pagination(e, page) {
    // this.callService(page);
    this.getDoctor(
      page,
      this.state.searchArry,
      100,
      this.state.filterStartDate,
      this.state.filterEndDate
    );
    this.props.history.push("/admin/doctor/" + page);
  }

  async handleSearch(event) {
    await this.setState({
      search: event.target.value,
    });
    // this.getChatUsers(this.props.match.params.page, this.state.search, 100, this.state.fromDate,this.state.toDate)
    // this.props.history.push("/admin/all-chat/" + 1);
  }

  callService(page, search = false) {
    getDoctors(page, search)
      .then(res => {
        console.info("res", res)
        this.setState(() => ({
          doctorData: res.data.result,
          nextPage: res.data.next,
          totalRecords: res.data.total,
          prevoiusPage: this.state.currentPage > 1 ? true : false,
        }));
      })
  }

  handleSearch = (e, field) => {
    this.setState({ search: e.target.value });
  }

  search(clear = false) {
    if (clear) {
      this.setState({
        search: ''
      });
    }
    setTimeout(() => {
      this.callService(1, this.state.search);
    }, 100);

  }

  handleSubmit = async (event) => {
    event.preventDefault();
    // this.callService(this.state.filterStartDate, this.state.filterEndDate)

    // console.log('filterStartDate',this.state.filterStartDate)

    this.getDoctor(
      1,
      this.state.searchArry,
      100,
      this.state.filterStartDate,
      this.state.filterEndDate
    );
  };

  async handleSearchMobile(event) {
    await this.setState({
      mobileNumber: event.target.value,
    });
    //  this.getChatUsers(this.props.match.params.page, this.state.search, 100, this.state.fromDate,this.state.toDate)
    // this.props.history.push("/admin/all-chat/" + 1);
  }

  async onRemove(event) {
    // debugger
    var array = [...this.state.searchArry]; // make a separate copy of the array
    var index = array.indexOf(event);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ searchArry: array });
      console.log("arrayarray", array);
      this.getDoctor(
        1,
        array,
        100,
        this.state.filterStartDate,
        this.state.filterEndDate
      );
    }
  }

  async handleDelete(e, id) {
    try {
      e.preventDefault();
      deleteDoctorImage(id)
        .then(async (res) => {
          this.getDoctor(this.props.match.params.page, this.state.search, 100);
          toast.success("Doctor Image removed successfully.");
        })
        .catch((error) => {
          if (error.response && error.response.data.message)
            toast.error(error.response.data.message);
          else if (error.message) toast.error(error.message);
          else toast.error(error);
        });

      // for image upload
      // if (this.state.fileObj) {
      //   let formData = new FormData()
      //   formData.append("media", this.state.fileObj)
      //   updateDoctorImage(this.props.openImageModal.data, formData).then(async(res) => {
      //     await this.setState({ reload: true })
      //     console.log(res)
      //     toast.success("Campaign Image uploaded successfully.")
      //     this.closeImageModal(true)

      //   }).catch(error => {
      //     if (error.response && error.response.data.message)
      //       toast.error(error.response.data.message)
      //     else if (error.message)
      //       toast.error(error.message)
      //     else
      //       toast.error(error)
      //   })

      // const res = await clientService.
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { selectChatUser, doctorData, searchArry } = this.state;
    const { nextPage } = this.state;
    const { chatUsers } = this.state;
    const { messages } = this.state;
    const { messageText } = this.state;

    return (
      <SiteWrapperSuperAdmin>
        <Page.Content>
          <div>
            <Grid.Row>
            <Form.Group label="" className="ml-auto">
                    <Form.InputGroup className="ml-auto1">
                      <Form.Input placeholder="Search..." className="ml-auto1 "
                        name="search"
                        value={this.state.search}
                        onChange={(e) => this.handleSearch(e, 'search')} />
                      <Form.InputGroupAppend>
                        <Button color="primary" className="ml-auto1 btn1a"
                          onClick={(e) => this.search()}>
                          {changeLanguage(this.props.siteLanguage, 'go')}
                        </Button>
                        <Button color="primary" className="ml-auto1 ml-auto10 btn1a"
                          onClick={(e) => this.search(true)}>
                          {changeLanguage(this.props.siteLanguage, 'clear')}
                        </Button>
                      </Form.InputGroupAppend>
                    </Form.InputGroup>
                  </Form.Group>
              {/* <div className="col-sm-1"><div class="ortext">OR</div></div>  */}
              <div className="col col-sm-5  mt-5">
                <div class="mobilenoinput ml-5">
                  {/* <form onSubmit={this.handleSubmitMobile} className="row align-items-center">
              <div className="col col-sm-100 ">
              <input name="search" className="form-control" type="text" 
                    placeholder="Search" 
                    value={this.state.search} 
                    onChange={(e) => this.handleSearch(e)}
                  />
                    </div>
                            <div className="col col-3 col-sm-2 ">
                            <Button color="primary " type="submit">Submit</Button>
                        </div>
                </form>   */}
                  <div className="searchlist">
                    <br />
                    {searchArry &&
                      searchArry.length > 0 &&
                      searchArry.map((value, index) => {
                        return (
                          <div className="appendlist">
                            {value}
                            <label
                              onClick={() => {
                                this.onRemove(value);
                              }}
                            >
                              x
                            </label>
                          </div>
                        );
                      })}
                    {/* {
                    // (this.state.showSearch && doctorData.length > 0) && 
                    (this.state.showSearch ) && 
                    <input name="search" className="form-control" type="text" 
                    placeholder="Search" 
                    value={this.state.search} 
                    onChange={(e) => this.handleSearch(e)}
                  />
                  } */}
                  </div>
                </div>
              </div>
            </Grid.Row>
            <Grid.Row className="justify-content-center">
              <div className="col col-sm-12">
                {/* //display button col-sm-5 */}

                <Card className="card01a mt-50">
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter  tablebackground"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader className="capitilize fontSize">
                          Name
                        </Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize">
                          branch
                        </Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize">
                          English Image
                        </Table.ColHeader>
                        {/* <Table.ColHeader className="capitilize fontSize">
                          Arabic Image
                        </Table.ColHeader> */}
                        <Table.ColHeader className="capitilize fontSize"></Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize"></Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {doctorData.length ? (
                        doctorData.map((doctorObj, childIndex) => (
                          <tr key={childIndex}>
                            <td className="w-1 sticky-col">
                              {doctorObj.name_en}
                            </td>

                            <td className="w-1 sticky-col">
                              {doctorObj.branch && doctorObj.branch.name_en}
                            </td>
                            <td className="w-1 sticky-col">
                              {/* {doctorObj.image_url && <a href={doctorObj.image_url} target="_blank" referrerPolicy="no-referrer">img</a> } */}
                              <img
                                src={doctorObj.image_url}
                                alt=""
                                style={{
                                  heigth: 75,
                                  width: 75,
                                  objectFit: "contain",
                                }}
                              />
                            </td>
                            <td className="w-1 sticky-col">
                              {/* {doctorObj.image_url && <a href={doctorObj.image_url} target="_blank" referrerPolicy="no-referrer">img</a> } */}
                              <img
                                src={doctorObj.image_url_ar}
                                alt=""
                                style={{
                                  heigth: 75,
                                  width: 75,
                                  objectFit: "contain",
                                }}
                              />
                            </td>
                            {/* <td className="cursorPointer sticky-col aligntd">
                              {optin.status}
                              </td> */}
                            <td className="w-1">
                              <a
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={(e) =>
                                  this.openImageModal(doctorObj._id)
                                }
                              >
                                Add/Change Image
                              </a>
                            </td>
                            <td className="w-1">
                              <a
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={(e) =>
                                  window.confirm(
                                    "Are you sure you want to delete this image?"
                                  ) && this.handleDelete(e, doctorObj._id)
                                }
                              >
                                Delete Image
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <Table.Row>
                          <Table.Col colSpan={3}>
                            Sorry! no data found.
                          </Table.Col>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                  {/* pagination */}
                  <div className="form-group" style={{ float: "right" }}>
                    <label className="form-label"></label>
                    <div className="selectgroup align-to-right paginations">
                      <span>{this.getPagination()}</span>

                      <i
                        className={
                          "fe " +
                          (this.props.match.params.page <= 1
                            ? "not-active "
                            : "cursorPointer ") +
                          " fe-chevron-left"
                        }
                        onClick={(e) =>
                          this.pagination(
                            e,
                            parseInt(this.props.match.params.page) - 1
                          )
                        }
                        disabled={this.props.match.params.page <= 1}
                      ></i>

                      <i
                        className={
                          "fe " +
                          (!nextPage ? "not-active " : "cursorPointer ") +
                          " fe-chevron-right"
                        }
                        onClick={(e) =>
                          this.pagination(
                            e,
                            parseInt(this.props.match.params.page) + 1
                          )
                        }
                        disabled={!nextPage}
                      ></i>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col col-sm-12"></div>
            </Grid.Row>

            <UploadImage
              openImageModal={this.state.openImageModal}
              onChange={this.closeImageModal}
            ></UploadImage>
          </div>
        </Page.Content>
        <ToastContainer autoClose={5000} position={"bottom-right"} />
      </SiteWrapperSuperAdmin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    activeBot: state.auth.activeBot,
    siteLanguage: state.auth.siteLanguage,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout());
    },
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));
