// @flow

import React from "react";
import { Grid, Card, Button, Icon } from "tabler-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import clientService from "../../services/client";
import Buttonloader from "../../common/Loader/Buttonloader";
import { changeLanguage } from "../../language/index";

class UpdateData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: {
        style: "success",
        text: "",
      },
      isLoadingSpecializations: false,
      isLoadingDoctors: false,
      errors: {},
    };
    this.updateDoctors = this.updateDoctors.bind(this);
    this.updateSpecializations = this.updateSpecializations.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
  }

  setLoadingToggle(param, value) {
    switch (param) {
      case "specializations":
        this.setState({ isLoadingSpecializations: value });
        break;
      case "doctors":
        this.setState({ isLoadingDoctors: value });
        break;
      default:
        break;
    }
  }

  updateSpecializations = (e) => {
    e.preventDefault();
    this.setLoadingToggle("specializations", true);
    clientService
      .updateSpecializations()
      .then((response) => {
        toast.success("Specializations updated successfully");
        this.setLoadingToggle("specializations", false);
      })
      .catch((error) => {
        this.setLoadingToggle("specializations", false);
        this.setState(() => ({
          message: { text: error.response.data.message, style: "danger" },
        }));
        setTimeout(() => {
          this.handleNotification();
        }, 3000);
      });
  };

  updateDoctors = () => {
    this.setLoadingToggle("doctors", true);
    clientService
      .updateDoctors()
      .then((response) => {
        toast.success("Doctors updated successfully");
        this.setLoadingToggle("doctors", false);
      })
      .catch((error) => {
        this.setLoadingToggle("doctors", false);
        this.setState(() => ({
          message: { text: error.response.data.message, style: "danger" },
        }));
        setTimeout(() => {
          this.handleNotification();
        }, 3000);
      });
  };

  componentDidMount() {
    document.title = "Voiceoc | Update Data";
  }

  handleNotification() {
    this.setState(() => ({ message: { text: "", style: "" } }));
  }

  render() {
    const { message } = this.state;

    return (
      <SiteWrapperSuperAdmin>
        <Grid.Col lg={5} className="mx-auto ">
          <Card>
            <Card.Header>
              <Card.Title>
                {/* {changeLanguage(this.props.siteLanguage, "change_password")} */}
                Update Data
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {!!message.text ? (
                <div className={"alert alert-" + message.style}>
                  <p>{message.text}</p>
                  <Icon
                    prefix="fa"
                    name="times"
                    onClick={this.handleNotification}
                  ></Icon>
                </div>
              ) : null}
              <div className="tabs" style={{ display: "flex" }}>
                <Button
                  color="primary"
                  className="aligncentre"
                  type="button"
                  onClick={this.updateSpecializations.bind(this)}
                  disabled={this.state.isLoadingSpecializations}
                >
                  {/* {changeLanguage(this.props.siteLanguage, "change")} */}
                  Update Specializations
                </Button>
                <Button
                  color="primary"
                  className="aligncentre"
                  type="button"
                  onClick={this.updateDoctors.bind(this)}
                  disabled={this.state.isLoadingDoctors}
                >
                  {/* {changeLanguage(this.props.siteLanguage, "change")} */}
                  Update Doctors
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Grid.Col>
        <ToastContainer autoClose={5000} position={"bottom-right"} />
      </SiteWrapperSuperAdmin>
    );
  }
}

export default UpdateData;
