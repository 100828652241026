import React from "react";
import Popup from "reactjs-popup";
import { Grid, Card, Table, Page, colors, Form } from "tabler-react";

class InfoPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.openInfoPopup,
    };
    //this.openModal = this.props.openPopup;
    this.closeInfoModal = this.closeInfoModal.bind(this);
  }

  closeInfoModal() {
    this.props.onChange(false);
  }

  submitReply(e) {
    this.props.onChange(false);
  }

  render() {
    // console.log('admin-engage-score',this.props.openInfoPopup.data)
    const openInfoPopup = this.props.openInfoPopup.open;
    const replyOption = this.props.openInfoPopup.reply || this.state.reply;
    const message = this.props.openInfoPopup.data;
    console.log("admin-engage-score", this.props);
    return (
      <div>
        <Popup
          open={openInfoPopup}
          onClose={this.closeInfoModal}
          closeOnDocumentClick
        >
          <div className="card">
            <div className="card-header">
              <div className="card-options">
                <a
                  className="card-options-remove cursorPointer"
                  data-toggle="card-remove"
                  onClick={() => {
                    this.closeInfoModal();
                  }}
                >
                  <i className="fe fe-x" />
                </a>
              </div>
            </div>
            <div className="card-body aligncenter">
              {/* <div>Color :{message && message.color}</div> */}

              {/* <div>Lab:{message && message.lab}</div> */}

              {message && message.email != "" && <p> Email: {message.email}</p>}

              {message && message.address != "" && (
                <p> Address: {message.address}</p>
              )}
              {message && message.pincode != "" && (
                <p> Pincode: {message.pincode}</p>
              )}
              {message && message.offer != "" && <p> Offer: {message.offer}</p>}
              {message && message.magazine_type != "" && (
                <p> Magazine Type: {message.magazine_type}</p>
              )}
              {message && message.programme != "" && (
                <p> Programme: {message.programme}</p>
              )}
              {message && message.plan != "" && <p> Plan: {message.plan}</p>}
              {message && message.summit != "" && (
                <p> Summit: {message.summit}</p>
              )}
              {message && message.time_slot != "" && (
                <p> Time slot: {message.time_slot}</p>
              )}

              {message && message.amount != "" && (
                <p> Amount: {message.amount}</p>
              )}

              {message && message.suggestion != "" && (
                <p> Lead Api Error: {message.suggestion}</p>
              )}

              {message && message.leadId != "" && (
                <p> LeadId: {message.leadId}</p>
              )}

              {/* <div>Mobile Number:{message && message.mobile_number}</div> */}

              {/* <div>Pincode:{"  "}{message && message.pincode}</div> */}

              {/* <div>
                Test Name:
                {message && message.test_name}
              </div> */}
              {/* <div>
                Test Type:
                {message && message.test_type}
              </div> */}

              {/* <div>Visit Postal Code:{message && message.visit_postal_code}</div> */}
              {/* <div>Package Name:{message && message.package_name}</div> */}
              {/* <div>Package Category:{message && message.package_category}</div> */}

              {/* <div>Booking Type:{message && message.booking_type}</div> */}
              {/* <div>Visit Address:{message && message.visit_address}</div> */}

              {/* <div>
                Prescription:{" "}
                <a
                  href={message && message.prescription}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  {message && message.prescription}
                </a>{" "}
              </div> */}

              {/* address
              email
              lab
              gender
              dob
              mobile_number
              pincode
              test_name
              visit_postal_code
              visit_address
              prescription */}

              {/* <div>
                Email: {message && message.email}
              </div>
              <div>
                Language: {message && message.language}
              </div> */}
              {/* <div>
                  Speciality: {message && message.speciality}
                </div> */}
              {/* <div>
                  Address: {message && message.address}
                </div> */}

              {/* <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter "
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Sl No.</Table.ColHeader>
                    <Table.ColHeader >Entity </Table.ColHeader>
                    <Table.ColHeader>Value</Table.ColHeader>                   
                  </Table.Row>
                </Table.Header>
               
                  <Table.Body>
                    {
                      message
                      && Array.isArray(message)
                      && message.length>0
                      &&  message.map((value, index) => (
                        <Table.Row key={index}>
                          <Table.Col>{index + 1}</Table.Col>
                          <Table.Col>{value.entity}</Table.Col>
                          <Table.Col className="text-nowrap">{value.value}</Table.Col>
                          
                        </Table.Row>
                      ))
                     
                    }
                  </Table.Body>
                 
                
              </Table> */}
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default InfoPopup;
