// @flow

import React from "react";
import { Grid, Card, Table, Page, colors, Form, Button } from "tabler-react";
import Select from "react-select";
import Moment from "moment";

import "tabler-react/dist/Tabler.css";
import C3Chart from "react-c3js";
import { Link, withRouter, browserHistory } from "react-router-dom";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";

import SiteWrapperAdmin from "../SiteWrapper.admin.react";
import authActions from "../../redux/auth/action";
import clientService from "../../services/client";
import ReadmorePopup from "../Readmore";
import InfoPopup from "../InfoPopup";
import socketIOClient from "socket.io-client";
import { changeLanguage } from "../../../src/language/index";
import $ from "jquery";
var { jwtToken } = localStorage;
// var ENDPOINT = "https://waagentportal.rpsapi.in";
// var ENDPOINT = 'http://13.235.244.230:5781';
var ENDPOINT = process.env.REACT_APP_baseUrl;
var socket;
var audio;

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.chatDiv = React.createRef();
    this.state = {
      messageText: "",
      currentPage: this.props.match.params.page,
      nextPage: false,
      totalRecords: 0,
      chatUsers: [],
      messages: [],
      selectChatUser: null,
      selectedFile: "",
      formDisabled: false,
      files: [],
      openPopup: false,
      openInfoPopup: false,
      page: 1,
      search: "",
      allContextData: [],
    };
    audio = new Audio("https://waportal.rpsapi.in/notification_tune.mp3");
    this.handleChange = this.handleChange.bind(this);
    // this.onChangeFile = this.onChangeFile.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.closeInfoPopup = this.closeInfoPopup.bind(this);
  }

  closePopup(data) {
    this.setState({
      openPopup: {
        open: false,
        data: false,
      },
    });
  }

  closeInfoPopup(data) {
    this.setState({
      openInfoPopup: {
        open: false,
        data: false,
      },
    });
  }

  openInfoPopup(e, data) {
    console.log("datadata", data);
    let arr = data.entity;
    if (data.prescription)
      arr.push({ entity: "prescription", value: data.prescription });
    if (data.test_mode)
      arr.push({ entity: "test_mode", value: data.test_mode });

    arr = arr.filter((item) => item.entity !== "summit");
    arr = arr.filter((item) => item.entity !== "plan");
    arr = arr.filter((item) => item.entity !== "day");
    arr = arr.filter((item) => item.entity !== "programme");

    if (data.summit) arr.push({ entity: "summit", value: data.summit });

    if (data.plan) arr.push({ entity: "plan", value: data.plan });

    if (data.offer) arr.push({ entity: "day", value: data.offer });
    if (data.programme)
      arr.push({ entity: "programme", value: data.programme });

    this.setState({
      openInfoPopup: {
        open: true,
        data: arr,
      },
    });
  }

  openPopup(e, data) {
    this.setState({
      openPopup: {
        open: true,
        data: data,
      },
    });
  }

  handleChange = (e, field) => {
    console.log(e.keyCode);
    const val = e.target.value;
    this.setState({ messageText: val });
    if (e.keyCode == 13 && e.shiftKey == false) {
      this.emitMessage(e);
    }
  };

  componentWillMount() {
    console.log("componentWillMount");
    this.unlisten = this.props.history.listen(async (location, action) => {
      let url = location.pathname.split("/");

      if (url != undefined && url[4] != undefined) {
        this.setState({
          clientId: url[4],
        });
        await this.getChatUsers(
          this.props.match.params.page,
          this.state.search,
          url[4]
        );
        // await this.getMessages();
      }
    });
  }

  async componentDidMount() {
    var hrefSplit = window.location.href.split("?");
    console.log(hrefSplit);
    if (hrefSplit.length > 1) {
      window.location.href = hrefSplit[0];
    }

    console.log("componentWillMount");
    document.title = "Voiceoc | Chat";
    //this.detectChnageURl();

    this.getChatUsers(
      this.props.match.params.page,
      this.state.search,
      this.props.user._id
    );
    socket = socketIOClient(ENDPOINT, { query: `authorization=${jwtToken}` });
    console.log(
      "this.props.match.params.userId asdf",
      this.props.match.params.userId
    );
    if (
      socket &&
      !socket.connected &&
      this.props.match.params.userId === undefined
    ) {
      await socket.once("connect", () => {
        console.log("connect", socket);
      });
    }

    socket.on("chatting", (data) => {
      console.log("chatting", data);
      if (data.event === "receive-message") {
        audio.play();
        this.getChatUsers(
          this.props.match.params.page,
          this.state.search,
          this.props.user._id,
          true
        );
      } else if (data.event === "initiate-chat") {
        audio.play();
        this.getChatUsers(
          this.props.match.params.page,
          this.state.search,
          this.props.user._id,
          true
        );
      } else if (data.event === "end-chat") {
        audio.play();
        this.getChatUsers(
          this.props.match.params.page,
          this.state.search,
          this.props.user._id,
          true
        );
      }
    });
  }

  handleScroll(event) {
    let self = this;
    if (event.target.scrollTop === 0) {
      //console.log('abc',event.target.scrollTop)
      let activePage = this.state.page + parseInt(1);
      this.getMessages(activePage);
    }
  }
  getFilterChatUser() {
    const id = this.props.match.params.userId;
    var chatUser = this.state.chatUsers.filter(function (user) {
      return user.mobileNumber === id;
    });
    if (chatUser.length) {
      this.setState({
        selectChatUser: chatUser[0],
      });
    }

    return "";
  }

  async getMessages(page = 1) {
    if (this.state.selectChatUser) {
      let chatId = this.state.selectChatUser.mobileNumber;
      await clientService.getAllMessages(chatId, page).then(async (res) => {
        if (res.statusCode === 200) {
          if (res.data.result && res.data.result.length) {
            var allMessage = res.data.result
              .reverse()
              .concat(this.state.messages);

            await this.setState(() => ({
              messages: allMessage,
              page: page,
            }));
          }
          if (page === 1) {
            await this.setState(() => ({
              messages: res.data.result,
              page: 1,
            }));
            this.scrollToBottom();
          }

          if (page > 1 && res.data.result && res.data.result.length) {
            this.chatDiv.current.scrollTop = 100;
          }
        }
      });
    }
  }

  scrollToBottom = () => {
    if (this.chatDiv.current) {
      this.chatDiv.current.scrollTop = this.chatDiv.current.scrollHeight;
    }
  };

  getChatUsers(
    page,
    search,
    clientId,
    highlited = false,
    sortKey = false,
    sortType = false,
    limit = 10
  ) {
    clientService
      .getAllChatUsers(page, search, limit, false, sortKey, sortType)
      .then((res) => {
        if (res.statusCode === 200) {
          let dataArray = res.data.result;

          if (highlited && dataArray.length > 0) {
            dataArray[0]["highlited"] = true;
          }
          this.setState(() => ({
            chatUsers: res.data.result,
            nextPage: res.data.next,
            totalRecords: res.data.total,
            prevoiusPage: this.state.currentPage > 1 ? true : false,
          }));
          this.getFilterChatUser();

          if (
            this.props.match.params &&
            this.props.match.params.userId != undefined
          ) {
            console.log("najmunajmu262");
            this.getMessages();
          }
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 201
        ) {
          this.props.logOut();
          this.props.history.push("/login");
        }
        console.log("najmunajmu273", error);
        console.log(error);
      });
  }

  // getFilterAdmin(id) {
  //   var admins = this.state.adminOptions.filter(function (admin) {
  //     return admin._id === id
  //   });
  //   if (admins.length) {
  //     return admins[0]
  //   }
  //   return '';
  // }

  // detectChnageURl() {
  //   if (this.props.match.params && this.props.match.params.id != undefined) {
  //     let label = this.getFilterAdmin(this.props.match.params.id);
  //     this.setState({
  //       selectedAdmin: { value: label._id, label: label.name }
  //     })
  //   }

  //   if (this.props.match.params && this.props.match.params.clientId != undefined) {
  //     this.setState({
  //       clientId: this.props.match.params.clientId
  //     })
  //   }
  // }

  //listen client change
  // selectAdmin = selectedAdmin => {
  //   this.props.history.push('/admin/dashboard/' + selectedAdmin.value + '/1');
  // }

  formatDate(date) {
    return Moment(date).format("DD/MM/YY h:mm a");
  }

  getPagination() {
    var page = this.props.match.params.page;
    var startRecord =
      this.props.match.params.page == 1
        ? this.props.match.params.page
        : 10 * (this.props.match.params.page - 1) + 1;
    var endRecord =
      parseInt(startRecord) + parseInt(this.state.chatUsers.length) - 1;
    return startRecord + "-" + endRecord;
    //+' of '+this.state.totalRecords;
  }

  async selectUserToChat(e, user) {
    await this.setState({
      selectChatUser: user,
    });

    await this.props.history.push(
      "/admin/all-chat/" +
        this.props.match.params.page +
        "/" +
        user.mobileNumber
    );
  }

  // async uploadFile() {
  //   let filesData;
  //   clientService.uploadFile(this.state.selectedFile)
  //     .then(response => {
  //       const filesData = response.data;

  //     })
  //     .catch(error => {
  //       console.log(error);

  //     });
  //   return filesData
  // }

  async emitMessage(event) {
    event.preventDefault();
    if (!this.state.selectChatUser) {
      toast.success("Please first select the user.");
      return false;
    }
    let customerDetail = this.state.selectChatUser;
    let emitData = {
      event: "send-message",
      data: {
        chatId: this.state.selectChatUser._id,
        mobileNumber: this.state.selectChatUser.mobileNumber,
        body: this.state.messageText,
        media: [],
        customerDetail,
      },
    };
    if (this.state.selectedFile) {
      clientService
        .uploadFile(this.state.selectedFile)
        .then((response) => {
          const filesData = response.data;
          emitData.data.media.push(filesData);
          console.log(emitData);
          socket.emit("chat", emitData);
          this.setState({ messageText: "", selectedFile: null });
          document.getElementById("chatForm").reset();
          this.getChatUsers(
            this.props.match.params.page,
            false,
            this.props.user._id,
            true
          );
          this.getMessages();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      if (this.state.messageText == "" || this.state.messageText == null) {
        toast.error("Please Type something.");
        return false;
      }
      socket.emit("chat", emitData);
      console.log("emitmessage", socket);
      this.setState({ messageText: "" });
      document.getElementById("chatForm").reset();
      this.getChatUsers(
        this.props.match.params.page,
        this.state.search,
        this.props.user._id,
        true
      );
      this.getMessages();
    }
  }

  // onChangeFile = (event) => {
  //   event.preventDefault();
  //   if (!this.state.selectChatUser) {
  //     toast.success('Please first select the user.');
  //     return false;
  //   }
  //   const data = new FormData();
  //   data.append('media', event.target.files[0]);
  //   this.setState({ selectedFile: data, fileName: event.target.files[0].name });
  // }

  chatEnd(e, selectChatUser) {
    if (!selectChatUser) {
      toast.success("Please first select the user.");
      return false;
    }
    let chatEndData = {
      chatId: selectChatUser._id,
      mobileNumber: selectChatUser.mobileNumber,
    };

    clientService
      .endChat(chatEndData)
      .then((response) => {
        toast.success("Chat end successfully");
        this.setState({
          formDisabled: true,
        });
        this.getChatUsers(
          this.props.match.params.page,
          this.state.search,
          this.props.user._id
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      console.log(e.keyCode, e.shiftKey, "eeeeeee");
    }
  };

  orderByChatList(e) {
    if (e.target.value === "latest") {
      this.getChatUsers(1, this.state.search, false, false);
    } else {
      var valueKey = e.target.value.split("_");
      this.getChatUsers(
        1,
        this.state.search,
        false,
        false,
        valueKey[1],
        valueKey[0]
      );
    }
  }

  pagination(e, page) {
    // this.callService(page);
    this.getChatUsers(page, this.state.search, false, false);
    this.props.history.push("/admin/all-chat/" + page);
  }

  async handleSearch(event) {
    await this.setState({
      search: event.target.value,
    });
    this.getChatUsers(
      this.props.match.params.page,
      this.state.search,
      false,
      false
    );
    // this.props.history.push("/admin/all-chat/" + 1);
  }

  render() {
    const { selectChatUser } = this.state;
    const { nextPage } = this.state;
    const { chatUsers } = this.state;
    const { messages } = this.state;
    const { messageText } = this.state;

    return (
      <SiteWrapperSuperAdmin>
        <Page.Content>
          <audio id="audio">
            <source src="/notification_tune.mp3" type="audio/mp3"></source>
          </audio>

          <div>
            <Grid.Row>
              <div className="col col-sm-6 ">
                <div className="page-header">
                  <div className="d-flex col-sm-6">
                    <select
                      className=" custom-select auto1"
                      onChange={(e) => this.orderByChatList(e)}
                    >
                      <option value="latest">
                        {changeLanguage(
                          this.props.siteLanguage,
                          "time_latest_first"
                        )}
                      </option>
                      <option value="asc_name">
                        {changeLanguage(this.props.siteLanguage, "name_a_z")}
                      </option>
                      <option value="desc_name">
                        {changeLanguage(this.props.siteLanguage, "name_z_a")}
                      </option>
                    </select>
                  </div>

                  <div className="d-flex col-sm-6">
                    <input
                      name="search"
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={this.state.search}
                      onChange={(e) => this.handleSearch(e)}
                    />
                  </div>
                </div>
              </div>
            </Grid.Row>
            <Grid.Row>
              <div className="col col-sm-6">
                {/* //display button col-sm-5 */}

                <Card className="card01a">
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter  tablebackground"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader className="capitilize fontSize">
                          {" "}
                        </Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize">
                          {" "}
                        </Table.ColHeader>

                        <Table.ColHeader className="capitilize fontSize aligntd">
                          {changeLanguage(this.props.siteLanguage, "name")}
                        </Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize aligntd">
                          {changeLanguage(this.props.siteLanguage, "date")}
                        </Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize aligntd">
                          {changeLanguage(
                            this.props.siteLanguage,
                            "mobile_number"
                          )}
                        </Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize">
                          Context{" "}
                        </Table.ColHeader>
                        {/* <Table.ColHeader className="capitilize fontSize">City</Table.ColHeader> */}
                        {/* <Table.ColHeader className="capitilize fontSize">HOSPITAL</Table.ColHeader> */}
                        {/* <Table.ColHeader className="capitilize fontSize">LANGUAGE</Table.ColHeader> */}
                        {/* <Table.ColHeader className="capitilize fontSize">Language </Table.ColHeader> */}
                        <Table.ColHeader className="capitilize fontSize"></Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {chatUsers.length ? (
                        chatUsers.map((user, childIndex) => (
                          <tr
                            key={childIndex}
                            className={
                              "" +
                              (selectChatUser &&
                              user.mobileNumber === selectChatUser.mobileNumber
                                ? "highlitedtr"
                                : "") +
                              (user.status === "active" ? "" : "") +
                              " "
                            }
                            style={{ cursor: "pointer" }}
                            onClick={(e) => this.selectUserToChat(e, user)}
                          >
                            <td className="w-1 sticky-col">
                              {user.color === "green" && (
                                <span className="avatar1"></span>
                              )}
                              {user.color === "red" && (
                                <span className="avatar2"></span>
                              )}
                              {user.color === "yellow" && (
                                <span className="avatar3"></span>
                              )}
                              {user.color === "blue" && (
                                <span className="avatar4"></span>
                              )}
                            </td>
                            <td className="w-1 sticky-col">
                              {user.highlited ? (
                                <span className="avatar1"></span>
                              ) : null}
                            </td>
                            <td className="cursorPointer sticky-col aligntd">
                              {user.status === "active" ? (
                                <span>{user.name}</span>
                              ) : (
                                <span>{user.name}</span>
                              )}
                            </td>
                            <td className="aligntd">
                              {this.formatDate(user.updatedAt)}
                            </td>

                            <td className="aligntd">{user.mobileNumber}</td>

                            <td className="">{user.context}</td>
                            {/* <td className="">
                              {user.city}
                            </td> */}
                            {/* <td className="">
                              {

user.hospital.charAt(0).toUpperCase() + user.hospital.slice(1)
                              }
                            </td> */}
                            {/* <td className="">
                              {

                                user.langugae.charAt(0).toUpperCase() + user.langugae.slice(1)}
                            </td> */}

                            <td className="w-1">
                              <a
                                className="icon"
                                onClick={(e) => this.openInfoPopup(e, user)}
                              >
                                {user &&
                                user.entity &&
                                user.entity.length > 0 ? (
                                  <i className="fe fe-info"></i>
                                ) : null}
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <Table.Row>
                          <Table.Col colSpan={7}>
                            Sorry! no data found.
                          </Table.Col>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                  {/* pagination */}
                  <div className="form-group" style={{ float: "right" }}>
                    <label className="form-label"></label>
                    <div className="selectgroup align-to-right paginations">
                      <span>{this.getPagination()}</span>

                      <i
                        className={
                          "fe " +
                          (this.props.match.params.page <= 1
                            ? "not-active "
                            : "cursorPointer ") +
                          " fe-chevron-left"
                        }
                        onClick={(e) =>
                          this.pagination(
                            e,
                            parseInt(this.props.match.params.page) - 1
                          )
                        }
                        disabled={this.props.match.params.page <= 1}
                      ></i>

                      <i
                        className={
                          "fe " +
                          (!nextPage ? "not-active " : "cursorPointer ") +
                          " fe-chevron-right"
                        }
                        onClick={(e) =>
                          this.pagination(
                            e,
                            parseInt(this.props.match.params.page) + 1
                          )
                        }
                        disabled={!nextPage}
                      ></i>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="col col-sm-6 topChatBoc">
                <div className="card card2">
                  <div className="card-header1">
                    <h3 className="card-title card-t1">
                      {selectChatUser
                        ? selectChatUser.name
                        : changeLanguage(this.props.siteLanguage, "chat")}
                    </h3>
                    <div className="card-options">
                      {/* <button
                        className={
                          " bg-black marginChatBox " +
                          (this.state.formDisabled ? " disablebutton" : " ") +
                          ""
                        }


                        onClick={(e) => { if (window.confirm('Are you sure to end chat?')) { this.chatEnd(e, selectChatUser) }; }}

                        disabled={this.state.formDisabled}
                      >{changeLanguage(this.props.siteLanguage,'end_chat')} {this.state.formDisabled}</button> */}
                    </div>
                  </div>
                  <div
                    className="card-body card1"
                    ref={this.chatDiv}
                    onScroll={(e) => this.handleScroll(e)}
                  >
                    <ul
                      className="list-unstyled 
                    list-separated"
                    >
                      {messages && messages.length
                        ? messages.map((message, childIndex) => (
                            <div>
                              {
                                message.messageFrom === "user" ||
                                message.messageFrom === "customer" ? (
                                  <li className="list-separated-item">
                                    <div
                                      className="row 
                          row align-items-center botchat"
                                    >
                                      <div className="col col-auto">
                                        <span
                                          className="avatar avatar-md 
                        d-block usertextcolor 
                          userbackground1"
                                        >
                                          C
                                        </span>
                                      </div>
                                      <div className="col">
                                        <div>
                                          <span className="text-inherit">
                                            {message &&
                                            Array.isArray(message.media) &&
                                            message.media.length > 0 &&
                                            message.media[0].mimeType !==
                                              null &&
                                            message.media[0].mimeType.split(
                                              "/"
                                            )[0] === "image" ? (
                                              // || message.media[0].mimeType === 'image/jpg'
                                              // || message.media[0].mimeType === 'image/png'
                                              <img src={message.media[0].url} />
                                            ) : message &&
                                              Array.isArray(message.media) &&
                                              message.media.length > 0 ? (
                                              <a
                                                target="_blank"
                                                href={message.media[0].url}
                                              >
                                                {message.media[0].originalName}{" "}
                                              </a>
                                            ) : (
                                              message.message
                                            )}
                                            <p>
                                              {this.formatDate(
                                                message.createdAt
                                              )}
                                            </p>
                                          </span>
                                        </div>
                                        <div></div>
                                      </div>
                                    </div>
                                  </li>
                                ) : (
                                  <li
                                    className={`list-separated-item ${
                                      message.messageFrom === "agent"
                                        ? "text-color"
                                        : ""
                                    } `}
                                  >
                                    <div
                                      className="row row 
                    align-items-center"
                                    >
                                      <div className="col">
                                        <div className="col01">
                                          <span className="text-inherit">
                                            {message &&
                                            Array.isArray(message.media) &&
                                            message.media.length > 0 &&
                                            message &&
                                            message.message ? (
                                              <>
                                                <a
                                                  target="_blank"
                                                  href={message.media[0].url}
                                                >
                                                  {
                                                    message.media[0]
                                                      .originalName
                                                  }{" "}
                                                </a>{" "}
                                                <br /> {message.message}{" "}
                                              </>
                                            ) : message &&
                                              Array.isArray(message.media) &&
                                              message.media.length > 0 &&
                                              message.media[0].mimeType !==
                                                null &&
                                              message.media[0].mimeType.split(
                                                "/"
                                              )[0] === "image" ? (
                                              // || message.media[0].mimeType === 'image/jpg'
                                              // || message.media[0].mimeType === 'image/png'
                                              <img src={message.media[0].url} />
                                            ) : message &&
                                              Array.isArray(message.media) &&
                                              message.media.length > 0 ? (
                                              <a
                                                target="_blank"
                                                href={message.media[0].url}
                                              >
                                                {message.media[0].originalName}{" "}
                                              </a>
                                            ) : (
                                              message.message
                                            )}
                                            <p>
                                              {this.formatDate(
                                                message.createdAt
                                              )}
                                            </p>
                                          </span>
                                        </div>
                                      </div>

                                      <div className="col col-auto">
                                        <span
                                          className="avatar avatar-md 
                      d-block usertextcolor 
                        userbackground"
                                        >
                                          M
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                )

                                //           <li className="list-separated-item">
                                //             <div className="row
                                //   row align-items-center botchat">
                                //               <div className="col col-auto">
                                //                 <span className="avatar avatar-md
                                // d-block usertextcolor
                                //   userbackground1">
                                //                   C</span></div>
                                //               <div className="col"><div>
                                //                 <span className="text-inherit" >
                                //                 {
                                //                     message
                                //                       && Array.isArray(message.media)
                                //                       && message.media.length > 0
                                //                       && message.media[0].mimeType.split('/')[0] === 'image'
                                //                       // || message.media[0].mimeType === 'image/jpg'
                                //                       // || message.media[0].mimeType === 'image/png'
                                //                       ? <img src={message.media[0].url} /> :
                                //                       (
                                //                         message
                                //                           && Array.isArray(message.media)
                                //                           && message.media.length > 0
                                //                           ? <a target="_blank" href={message.media[0].url}>{message.media[0].originalName} </a> : message.message
                                //                       )

                                //                   }
                                //                   <p >
                                //                     {this.formatDate(message.createdAt)}
                                //                   </p>
                                //                 </span></div>
                                //                 <div></div>
                                //               </div>

                                //             </div>
                                //           </li>
                              }
                            </div>
                          ))
                        : null}
                    </ul>
                  </div>

                  <div className="car18">
                    <form id="chatForm">
                      {/* 
                      <Form.Group label="">

                        <div className="form-group"><textarea className="form-control" name="messageText"

                          placeholder="Type"
                          onChange={(e) => this.handleChange(e, 'messageText')}
                          onKeyDown={(e) => this.handleChange(e, 'messageText')}
                          disabled={this.state.formDisabled}

                        ></textarea></div>

                      </Form.Group> */}

                      {/* <div className="form-group">
                        <input type="file"
                          disabled={this.state.formDisabled}
                          name="file" id="file" className="upload-file"
                          onChange={(e) => this.onChangeFile(e)} />
                        <button className="btn btn-primary pull-right"
                          disabled={this.state.formDisabled}
                          onClick={(e) => this.emitMessage(e)}
                        >
                          {changeLanguage(this.props.siteLanguage,'send_message')}
                  </button>

                      </div> */}
                    </form>
                    <div className="pull-right dis-non">
                      <span
                        className="stamp stamp-md  bg-1 marginBottom10"
                        onClick={(e) => this.openPopup(e, "123")}
                      >
                        <i className="fe fe-dollar-sign"></i>
                      </span>
                      <span
                        className="stamp stamp-md  bg-2   marginBottom10"
                        onClick={(e) => this.openPopup(e, "123")}
                      >
                        <i className="fe fe-shopping-cart"></i>
                      </span>
                      <span className="stamp stamp-md bg-3   marginBottom10">
                        <i
                          className="fe fe-users"
                          onClick={(e) => this.openPopup(e, "123")}
                        ></i>
                      </span>
                      <span
                        className="stamp stamp-md bg-4   marginBottom10"
                        onClick={(e) => this.openPopup(e, "123")}
                      >
                        <i className="fe fe-message-square"></i>
                      </span>
                      <span
                        className="stamp stamp-md btn bg-5   marginBottom10"
                        onClick={(e) => this.openPopup(e, "123")}
                      >
                        <i className="fe fe-shopping-cart"></i>
                      </span>
                      <span
                        className="stamp stamp-md btn bg-6   marginBottom10"
                        onClick={(e) => this.openPopup(e, "123")}
                      >
                        <i className="fe fe-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <ReadmorePopup
                openPopup={this.state.openPopup}
                onChange={this.closePopup}
              ></ReadmorePopup>
              <InfoPopup
                openInfoPopup={this.state.openInfoPopup}
                onChange={this.closeInfoPopup}
              ></InfoPopup>
            </Grid.Row>
          </div>
        </Page.Content>
        <ToastContainer autoClose={5000} position={"bottom-right"} />
      </SiteWrapperSuperAdmin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    activeBot: state.auth.activeBot,
    siteLanguage: state.auth.siteLanguage,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout());
    },
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));
