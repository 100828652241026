// @flow

import React from "react";
import { Grid, Card, Table, Page, colors, Form, Button } from "tabler-react";
import Select from "react-select";
import Moment from "moment";

import "tabler-react/dist/Tabler.css";
import C3Chart from "react-c3js";
import { Link, withRouter, browserHistory } from "react-router-dom";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import InfoPopup from "./InfoPopup";
import SiteWrapperAdmin from "../SiteWrapper.admin.react";
import authActions from "../../redux/auth/action";
import clientService from "../../services/client";
import ReadmorePopup from "../Readmore";
import socketIOClient from "socket.io-client";
import { changeLanguage } from "../../../src/language/index";
import $ from "jquery";
import "../../c3jscustom.css";
import "c3/c3.css";
import UpdateStatusPopup from "./UpdateStatusPopup";
import DownloadPopup from "./downloadPopup";

const moment = require("moment");
const _ = require("lodash");
var { jwtToken } = localStorage;
// var ENDPOINT = "https://waagentportal.rpsapi.in";
// var ENDPOINT = 'http://13.235.244.230:5781';
var ENDPOINT = "http://3.108.74.209:3056";
var socket;
var audio;

class Chat extends React.Component {
  constructor(props) {
    let dataSingleChart = {
      bindto: "#graph3",
      x: "x",
      columns: [["x", null], ["Total Users", null]],
      colors: {
        "Total Users": "#0A49B6",
      },
    };
    super(props);
    this.chatDiv = React.createRef();
    this.state = {
      openDownloadPopup: {
        open: false,
        data: false,
      },
      messageText: "",
      currentPage: this.props.match.params.page,
      nextPage: false,
      totalRecords: 0,
      chatUsers: [],
      messages: [],
      selectChatUser: null,
      selectedFile: "",
      formDisabled: false,
      files: [],
      openPopup: false,
      openInfoPopup: false,
      openUpdateStatusPopup: {
        open: false,
        data: {},
      },
      page: 1,
      search: "",
      allContextData: [],
      startDate: "",
      endDate: "",
      filterStartDate: "",
      filterEndDate: "",
      optionData: [],
      showSearch: false,
      mobileNumber: "",

      startDateSingle: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
      endDateSingle: new Date(),
      filterStartDateSingle: new Date(
        Date.now() - 7 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-US"),
      filterEndDateSingle: new Date().toLocaleDateString("en-US"),
      optinGraph: [],
      xAxisSingleData: [],
      countDataSingleUnique: [],
      chartDataAvgReadValue: dataSingleChart,
      searchArry: [],
    };
    this.handleChange = this.handleChange.bind(this);
    // this.onChangeFile = this.onChangeFile.bind(this);
    this.closeInfoPopup = this.closeInfoPopup.bind(this);
    this.closeUpdateStatusPopup = this.closeUpdateStatusPopup.bind(this);
    this.closeDownloadPopup = this.closeDownloadPopup.bind(this);
  }

  handleChange = (type, date) => {
    let changeDate = new Date(date).toLocaleDateString("en-US");
    this.setState({
      [type]: date,
    });
    if (type === "startDate") {
      this.setState({ filterStartDate: changeDate });
    } else {
      this.setState({ filterEndDate: changeDate });
    }
  };
  closeDownloadPopup() {
    this.setState({
      openDownloadPopup: {
        open: false,
        data: false,
      },
    });
  }

  openDownloadPopup() {
    this.setState({
      openDownloadPopup: {
        open: true,
        data: false,
      },
    });
  }
  closeInfoPopup(data) {
    this.setState({
      openInfoPopup: {
        open: false,
        data: false,
      },
    });
  }
  openUpdateStatusPopup(data = {}) {
    this.setState({
      openUpdateStatusPopup: {
        open: true,
        data: { manual_status: "", ...data },
      },
    });
  }

  closeUpdateStatusPopup(data) {
    this.setState({
      openUpdateStatusPopup: {
        open: false,
        data: {},
      },
    });
    this.getChatUsers(this.props.match.params.page, this.state.search, 10);
  }

  openInfoPopup(id) {
    clientService.getLeadDashboardDetails(id).then((res) => {
      this.setState({
        openInfoPopup: {
          open: true,
          data: res.data,
        },
      });
    });
  }
  componentWillMount() {}

  async componentDidMount() {
    var hrefSplit = window.location.href.split("?");
    console.log(hrefSplit);
    if (hrefSplit.length > 1) {
      window.location.href = hrefSplit[0];
    }
    document.title = "Voiceoc | Chat";
    // this.callServiceOptin(this.state.filterStartDateSingle,this.state.filterEndDateSingle)

    this.getChatUsers(this.props.match.params.page, this.state.search, 10);
  }

  getChatUsers(page, search, limit = 10, fromDate, toDate) {
    clientService
      .getLeadDashboard(page, search, limit, fromDate, toDate)
      .then((res) => {
        // debugger
        if (res.statusCode === 200) {
          console.log("resresres", res.data.result);
          // let dataArray = res.data.result;

          // if (highlited && dataArray.length>0) {
          //   dataArray[0]['highlited'] = true;
          // }
          this.setState(() => ({
            optionData: res.data.result,
            nextPage: res.data.next,
            totalRecords: res.data.total,
            prevoiusPage: this.state.currentPage > 1 ? true : false,
          }));
          // this.getFilterChatUser();

          // if (this.props.match.params && this.props.match.params.userId != undefined) {
          //   console.log('najmunajmu262')
          //   this.getMessages()
          // }
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.statusCode === 201
        ) {
          this.props.logOut();
          this.props.history.push("/login");
        }
        console.log(error);
      });
  }

  formatDate(date) {
    return Moment(date).format("DD/MM/YY h:mm a");
  }

  getPagination() {
    var page = this.props.match.params.page;
    var startRecord =
      this.props.match.params.page == 1
        ? this.props.match.params.page
        : 10 * (this.props.match.params.page - 1) + 1;
    var endRecord =
      parseInt(startRecord) + parseInt(this.state.optionData.length) - 1;
    return startRecord + "-" + endRecord + " of " + this.state.totalRecords;
  }

  async selectUserToChat(e, user) {
    await this.setState({
      selectChatUser: user,
    });

    await this.props.history.push(
      "/admin/optin/" + this.props.match.params.page
    );
  }

  orderByChatList(e) {
    // if (e.target.value === 'latest') {
    //   this.getChatUsers(1, this.state.search, false, false)
    // } else {
    //   var valueKey = e.target.value.split('_');
    //   this.getChatUsers(1, this.state.search, false, false, valueKey[1], valueKey[0])
    // }
  }

  pagination(e, page) {
    // this.callService(page);
    this.getChatUsers(
      page,
      this.state.searchArry,
      10,
      this.state.filterStartDate,
      this.state.filterEndDate
    );
    this.props.history.push("/admin/lead-dashboard/" + page);
  }

  async handleSearch(event) {
    await this.setState({
      search: event.target.value,
    });
    // this.getChatUsers(this.props.match.params.page, this.state.search, 10, this.state.fromDate,this.state.toDate)
    // this.props.history.push("/admin/all-chat/" + 1);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    // this.callService(this.state.filterStartDate, this.state.filterEndDate)

    // console.log('filterStartDate',this.state.filterStartDate)

    this.getChatUsers(
      1,
      this.state.searchArry,
      10,
      this.state.filterStartDate,
      this.state.filterEndDate
    );
  };

  async handleSearchMobile(event) {
    await this.setState({
      mobileNumber: event.target.value,
    });
    //  this.getChatUsers(this.props.match.params.page, this.state.search, 10, this.state.fromDate,this.state.toDate)
    // this.props.history.push("/admin/all-chat/" + 1);
  }

  handleSubmitMobile = async (event) => {
    event.preventDefault();

    await this.setState({
      searchArry: [...this.state.searchArry, this.state.search],
    });
    await this.setState({
      search: "",
    });
    this.getChatUsers(
      1,
      this.state.searchArry,
      10,
      this.state.filterStartDate,
      this.state.filterEndDate
    );

    //   this.getChatUsers(1,false,10,false, false,this.state.mobileNumber)
  };

  async onRemove(event) {
    // debugger
    var array = [...this.state.searchArry]; // make a separate copy of the array
    var index = array.indexOf(event);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ searchArry: array });
      console.log("arrayarray", array);
      this.getChatUsers(
        1,
        array,
        10,
        this.state.filterStartDate,
        this.state.filterEndDate
      );
    }
  }

  
  render() {
    const { selectChatUser, optionData, searchArry } = this.state;
    const { nextPage } = this.state;
    const { chatUsers } = this.state;
    const { messages } = this.state;
    const { messageText } = this.state;

    const { user } = this.props;
    const Wrapper =
      user.role === "admin" ? SiteWrapperAdmin : SiteWrapperSuperAdmin;

    return <Wrapper>
        <Page.Content>
          <div>
            <div className="text-right">
              <button className="btn btn-primary sub2" onClick={() => {
                  this.openDownloadPopup();
                }}>
                {" "}
                Download
              </button>
            </div>

            <Grid.Row>
            <div className="datepicker-container">
                <div className="box-al">
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col col-sm-5 ">
                        <label className="form-label">From Date</label>
                        <DatePicker className="form-control" selected={this.state.startDate} onChange={(e) => this.handleChange("startDate", e)} maxDate={this.state.endDate} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" />
                      </div>

                      <div className="col col-6 col-sm-5 ">
                        <label className="form-label">To Date</label>
                        <DatePicker className="form-control" selected={this.state.endDate} onChange={(e) => this.handleChange("endDate", e)} maxDate={new Date()} minDate={this.state.startDate} dateFormat="dd/MM/yyyy" placeholderText="DD/MM/YYYY" />
                      </div>
                      <div className="col col-6 col-sm-2">
                        <br />
                        <Button color="primary sub2 mt-1" type="submit">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="col-sm-1"><div class="ortext">OR</div></div>  */}
              <div className="col col-sm-5  mt-5">
                <div class="mobilenoinput ml-5">
                  <form onSubmit={this.handleSubmitMobile} className="row align-items-center">
                    <div className="col col-sm-10 ">
                      <input name="search" className="form-control" type="text" placeholder="Search" value={this.state.search} onChange={(e) => this.handleSearch(e)} />
                    </div>
                    <div className="col col-3 col-sm-2 ">
                      <Button color="primary " type="submit">
                        Submit
                      </Button>
                    </div>
                  </form>
                  <div className="searchlist">
                    <br />
                    {searchArry && searchArry.length > 0 && searchArry.map(
                        (value, index) => {
                          return (
                            <div className="appendlist">
                              {value}
                              <label
                                onClick={() => {
                                  this.onRemove(value);
                                }}
                              >
                                x
                              </label>
                            </div>
                          );
                        }
                      )}
                    {/* {
                    // (this.state.showSearch && optionData.length > 0) && 
                    (this.state.showSearch ) && 
                    <input name="search" className="form-control" type="text" 
                    placeholder="Search" 
                    value={this.state.search} 
                    onChange={(e) => this.handleSearch(e)}
                  />
                  } */}
                  </div>
                </div>
              </div>
            </Grid.Row>
            <Grid.Row className="justify-content-center">
              <div className="col col-sm-12">
                {/* //display button col-sm-5 */}

                <Card className="card01a mt-50">
                  <Table cards={true} striped={true} responsive={true} className="table-vcenter  tablebackground">
                  <Table.Header>
                        <Table.ColHeader className="capitilize fontSize">
                          Created At
                        </Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize">
                          Mobile Number{" "}
                        </Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize">
                          Name
                        </Table.ColHeader>
                     
                        <Table.ColHeader className="capitilize fontSize">
                          Context
                        </Table.ColHeader>
                    
                        <Table.ColHeader className="capitilize fontSize">
                          Purpose
                        </Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize">
                          Status
                        </Table.ColHeader>
                        <Table.ColHeader className="capitilize fontSize" />
                        <Table.ColHeader className="capitilize fontSize" />
                  </Table.Header>
                  <Table.Body>
                      {optionData.length ? optionData.map(
                          (optin, childIndex) => (
                            <tr key={childIndex}>
                              <td className="w-1 sticky-col">
                                {new Date(optin.createdAt).toDateString()}
                              </td>
                              <td className="w-1 sticky-col">
                                {optin.mobileNumber}
                              </td>
                              <td className="w-1 sticky-col">
                                {optin.name}
                              </td>
                           
                            
                              <td className="w-1 sticky-col">
                                {optin.context}
                              </td>
                          
                              <td className="w-1 sticky-col">
                                {optin.purpose}
                              </td>
                              <td className="w-1 sticky-col">
                                {optin.manual_status}
                              </td>
                              <td className="w-1">
                                <a
                                  className="icon"
                                  onClick={(e) =>
                                    this.openUpdateStatusPopup(optin)
                                  }
                                >
                                  <i className="fe fe-edit" />
                                </a>
                              </td>
                              <td className="w-1">
                                <a
                                  className="icon"
                                  onClick={(e) =>
                                    this.openInfoPopup(optin._id)
                                  }
                                >
                                  <i className="fe fe-info" />
                                </a>
                              </td>
                            </tr>
                          )
                        ) : <Table.Row>
                          <Table.Col colSpan={3}>
                            Sorry! no data found.
                          </Table.Col>
                        </Table.Row>}
                    </Table.Body>
                  </Table>
                  {/* pagination */}
                  <div className="form-group" style={{ float: "right" }}>
                    <label className="form-label" />
                    <div className="selectgroup align-to-right paginations">
                      <span>{this.getPagination()}</span>

                      <i className={"fe " + (this.props.match.params.page <= 1 ? "not-active " : "cursorPointer ") + " fe-chevron-left"} onClick={(e) => this.pagination(e, parseInt(this.props.match.params.page) - 1)} disabled={this.props.match.params.page <= 1} />

                      <i className={"fe " + (!nextPage ? "not-active " : "cursorPointer ") + " fe-chevron-right"} onClick={(e) => this.pagination(e, parseInt(this.props.match.params.page) + 1)} disabled={!nextPage} />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col col-sm-12" />
            </Grid.Row>

            <InfoPopup openInfoPopup={this.state.openInfoPopup} onChange={this.closeInfoPopup} />
            <DownloadPopup openInfoPopup={this.state.openDownloadPopup} onChange={this.closeDownloadPopup} />
            {this.state.openUpdateStatusPopup.open && <UpdateStatusPopup openUpdateStatusPopup={this.state.openUpdateStatusPopup} onChange={this.closeUpdateStatusPopup} data={this.state.openUpdateStatusPopup.data} />}
          </div>
        </Page.Content>
        <ToastContainer autoClose={5000} position={"bottom-right"} />
      </Wrapper>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    activeBot: state.auth.activeBot,
    siteLanguage: state.auth.siteLanguage,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout());
    },
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Chat)
);
