// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button,
  Icon,
  Page

} from "tabler-react";
import Select from 'react-select';
import clientService from "../../../services/client";
import { validate } from '../../../validate';
import { toast, ToastContainer } from 'react-toastify';
import Buttonloader from '../../../common/Loader/Buttonloader'
import 'react-toastify/dist/ReactToastify.css';
import { changeLanguage } from "../../../language";
import { createCampaign, getAllCategories, updateCampaignImage } from "../../../services/campaign";
import SiteWrapperCampaignSuperAdmin from "../SiteWrapper.campaign.superadmin.react";


const validationRules = [
  {
    field: 'name_en',
    validations: ['required'],
    name: 'Name'
  },
  {
    field: 'categoryId',
    validations: ['required'],
    name: 'Category'
  },
  // {
  //   field: 'name_ar',
  //   validations: ['required'],
  //   name: 'Arabic Name'
  // },
];

class CategoryCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name_en: '',
        name_ar: '',
        categoryId: '',
        text_data_en: '',
        text_data_ar: '',
      },
      message: {
        style: 'success',
        text: ''
      },
      fileObj: null,
      isLoading: false,
      categoryArr: [],
      errors: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);

  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  //handle change
  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    this.validate();
  }

  onChangeFileUser = (event) => {
    // debugger
    if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/jpg" || event.target.files[0].type === "image/png") {

      // data.append('clientId', this.props.match.params.clientId);
      this.setState({ fileObj: event.target.files[0] });
    }
    else {
      toast.error('Please select only image');
    }
  }


  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Create Campaign"
    getAllCategories()
      .then(res => {
        console.info(res)
        this.setState(() => ({
          categoryArr: res.data.result,
        }));
      }).catch(error => {
        if (error.response && error.response.data.message)
          toast.error(error.response.data.message)
        else if (error.message)
          toast.error(error.message)
        else
          toast.error(error)

      })
  }

  //submit form
  handleSubmit = async event => {
    event.preventDefault();

    if (this.validate()) {
      this.setLoadingToggle(true);
      let data = {
        name_en: this.state.fields.name_en,
        name_ar: this.state.fields.name_ar,
        categoryId: this.state.fields.categoryId,
        text_data_en: this.state.fields.text_data_en,
        text_data_ar: this.state.fields.text_data_ar,
      }
      createCampaign(data, this.props.match.params.id)
        .then(response => {
          console.log(response)
          this.setLoadingToggle(false)
          toast.success("Campaign Added Successfully.")
          if (this.state.fileObj) {
            let formData = new FormData()
            formData.append("media", this.state.fileObj)
            updateCampaignImage(formData, response.data.data._id).then((res) => {
              console.log(res)
              toast.success("Campaign Image Uploaded successfully.")
            }).catch(error => {
              this.setLoadingToggle(false)
              if (error.response && error.response.data.message)
                toast.error(error.response.data.message)
              else if (error.message)
                toast.error(error.message)
              else
                toast.error(error)
            })

            setTimeout(() => {
              this.props.history.push("/admin/campaigntabs/campaign/1");
            }, 3000)
          }
          else {
            setTimeout(() => {
              this.props.history.push("/admin/campaigntabs/campaign/1");
            }, 3000)
          }
        })
        .catch(error => {
          this.setLoadingToggle(false)
          if (error.response && error.response.data.message)
            toast.error(error.response.data.message)
          else if (error.message)
            toast.error(error.message)
          else
            toast.error(error)
        })

    }
  }

  render() {
    return (
      <SiteWrapperCampaignSuperAdmin>
        <Page.Content>
          <Grid.Row>
            <Grid.Col lg={5} className="mx-auto ">
              <Card>
                <Card.Header>
                  <Card.Title>Add Package</Card.Title>
                </Card.Header>
                <Card.Body>
                  <form onSubmit={this.handleSubmit}>
                    <Form.FieldSet>
                      <Form.Group
                        isRequired
                        label={changeLanguage(this.props.siteLanguage, 'name_en')}
                      >
                        <Form.Input name="name_en"
                          onChange={(e) => this.handleChange(e, 'name_en')}
                          placeholder={changeLanguage(this.props.siteLanguage, 'name_en')}
                          value={this.state.fields.name_en} />
                        <label style={{ display: this.state.errors.name_en ? 'block' : 'none' }} className="error">{this.state.errors.name_en}</label>
                      </Form.Group>

                      {/* <Form.Group
                        isRequired
                        label={changeLanguage(this.props.siteLanguage, 'name_ar')}
                      >
                        <Form.Input name="name_ar"
                          onChange={(e) => this.handleChange(e, 'name_ar')}
                          placeholder={changeLanguage(this.props.siteLanguage, 'name_ar')}
                          value={this.state.fields.name_ar} />
                        <label style={{ display: this.state.errors.name_ar ? 'block' : 'none' }} className="error">{this.state.errors.name_ar}</label>
                      </Form.Group> */}

                      <Form.Group
                        isRequired
                        label={changeLanguage(this.props.siteLanguage, 'category')}
                      >
                        <Form.Select
                          name={changeLanguage(this.props.siteLanguage, 'category')}
                          onChange={(e) => this.handleChange(e, 'categoryId')}
                          value={this.state.fields.categoryId}
                        >
                          <option value="">
                            {changeLanguage(this.props.siteLanguage, 'please_select_category')}
                          </option>
                          {
                            this.state.categoryArr && this.state.categoryArr.length && this.state.categoryArr.map(el => (
                              <option key={el._id} value={el._id}>{el.name_en}</option>
                            ))
                          }
                        </Form.Select>
                        <label style={{ display: this.state.errors.categoryId ? 'block' : 'none' }} className="error">{this.state.errors.categoryId}</label>
                      </Form.Group>
                      <h5>Data : </h5>

                      <Form.Group
                        label={changeLanguage(this.props.siteLanguage, 'text_data_en')}
                      >
                        <Form.Input name="text_data_en"
                          onChange={(e) => this.handleChange(e, 'text_data_en')}
                          placeholder={changeLanguage(this.props.siteLanguage, 'text_data_en')}
                          value={this.state.fields.text_data_en} />
                        <label style={{ display: this.state.errors.text_data_en ? 'block' : 'none' }} className="error">{this.state.errors.text_data_en}</label>
                      </Form.Group>

                      {/* <Form.Group
                        label={changeLanguage(this.props.siteLanguage, 'text_data_ar')}
                      >
                        <Form.Input name="text_data_ar"
                          onChange={(e) => this.handleChange(e, 'text_data_ar')}
                          placeholder={changeLanguage(this.props.siteLanguage, 'text_data_ar')}
                          value={this.state.fields.text_data_ar} />
                        <label style={{ display: this.state.errors.text_data_ar ? 'block' : 'none' }} className="error">{this.state.errors.text_data_ar}</label>
                      </Form.Group> */}

                      <h6>
                        {changeLanguage(this.props.siteLanguage, 'image_data')}:
                      </h6>

                      <div className="file">
                        <span className="file-custom">
                          <input
                            name="example-text-input"
                            type="file"

                            onChange={(e) => this.onChangeFileUser(e)}
                          />
                        </span>

                      </div>
                    </Form.FieldSet>
                    {!this.state.isLoading ?
                      <Button color="primary btn-block" type="submit">Add</Button>
                      :
                      <Buttonloader loading={this.state.isLoading}></Buttonloader>
                    }
                  </form>
                  <ToastContainer autoClose={5000} position={'bottom-right'} />
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>
      </SiteWrapperCampaignSuperAdmin >
    )
  }
}

export default CategoryCreate;

